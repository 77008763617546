<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <form v-else>
      <b-row class="no-gutters mb-3">
        <b-col>
          <div class="font-weight-bold ft-20 header-tablepage">
            Send Message Detail
          </div>
        </b-col>
        <b-col class="text-right">
          <b-button class="btn-download" @click.prevent="downloadTemplate">
            Download Template
          </b-button>
        </b-col>
      </b-row>
      <b-tabs v-model="tabIndex">
        <b-tab title="Set Up Message" active>
          <!-- general information -->
          <div class="bg-white black-color">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col class="text-left">
                  <button
                    @click.prevent="showCollapse(1)"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="generalInformationSelect"
                      >General Information</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col
                  class="text-right"
                  v-if="!showInformation && disableStatus === false"
                >
                  <button
                    class="btn-edit w-100 text-right"
                    @click.prevent="showInformation = true"
                  >
                    Edit
                  </button>
                </b-col>
                <b-col
                  v-if="showInformation && disableStatus === false"
                  class="text-right"
                >
                  <button
                    class="btn-edit-general btn-line-right py-0"
                    @click.prevent="saveInformation"
                  >
                    Save
                  </button>
                  <button
                    class="btn-edit-general py-0"
                    @click.prevent="showInformation = false"
                  >
                    Cancel
                  </button>
                </b-col>
              </b-row>
            </div>
            <div v-if="showInformation" class="p-3">
              <GeneralPanel
                :disableStatus="disableStatus"
                :v="$v"
                :form="form"
                :campaignList="campaignList"
                @getSendCustomer="getSendCustomer"
              />
            </div>
          </div>
          <!-- condition -->
          <div class="mt-3 bg-white black-color">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <button
                    @click.prevent="sideBarCondition"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="conditionSelect" class="pointer"
                      >Conditions (Segmentation)</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col class="text-right">
                  <button
                    class="btn-edit w-100 text-right"
                    v-if="!disableStatus"
                    @click.prevent="sideBarCondition"
                  >
                    Edit
                  </button>
                </b-col>
              </b-row>
            </div>
            <div class="p-3" v-if="conditionSettingValue">
              <DisplayResultAutomation
                :memberLevel="memberLevel"
                :stampList="stampCard"
                :gender="genderList"
                :branchList="branchList"
                :productList="productList"
                :campaignList="campaignList"
                :serviceList="serviceList"
                :privilegeList="privilegeCodeList"
                :settingValue="conditionSettingValue"
                :conditionList="conditionList"
                :form="form"
                :estimatedCustomer="estimatedCustomer"
                :display="3"
                :customerTag="customerTag"
              />
            </div>
          </div>
          <div class="bg-white mt-3 black-color">
            <Goal
              :form="form"
              @submitGoal="submitGoal"
              id="goal-panel"
              ref="goal"
            />
          </div>
          <!-- action -->
          <div class="bg-white mt-3 black-color">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <button
                    @click.prevent="sideBarAction"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="actionSelect"
                      >Privilege Use</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col class="text-right">
                  <button
                    class="btn-edit w-100 text-right"
                    @click.prevent="sideBarAction"
                  >
                    Edit
                  </button>
                </b-col>
              </b-row>
            </div>

            <div class="p-3" v-if="actionSettingValue && actionSelect">
              <DisplayResultAutomation
                :form="form"
                :display="4"
                :redeemCodeList="redeemCodeList"
                :settingValue="actionSettingValue"
                :estimatedCustomer="estimatedCustomer"
              />
            </div>
          </div>
          <div class="bg-white mt-3 black-color">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <button
                    @click.prevent="$refs.sidebarMemberTier.show()"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="attrSelect"
                      >Set Customer's Attribute</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col class="text-right">
                  <button
                    class="btn-edit w-100 text-right"
                    @click.prevent="$refs.sidebarMemberTier.show()"
                  >
                    Edit
                  </button>
                </b-col>
              </b-row>
            </div>

            <div class="p-3" v-if="attrSelect && attrSettingValue != 0">
              <DisplayResultAutomation
                :form="form"
                :display="5"
                :settingValue="attrSettingValue"
                :memberLevel="memberLevel"
              />
            </div>
          </div>
          <div class="bg-white mt-3 black-color">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <button
                    @click.prevent="sidebarConsent"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="isConsentSet" class="pointer"
                      >Consent Status</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col class="text-right">
                  <button
                    class="btn-edit w-100 text-right"
                    v-if="!disableStatus"
                    @click.prevent="sidebarConsent"
                  >
                    Edit
                  </button>
                </b-col>
              </b-row>
            </div>
            <div class="p-3" v-if="isConsentSet">
              <b-row
                ><b-col
                  >Consents Status :
                  {{
                    form.is_not_consent_send == 0
                      ? "Consent"
                      : form.is_not_consent_send == 2
                      ? "Not Consent"
                      : "All"
                  }}</b-col
                ></b-row
              >
            </div>
          </div>
          <!-- communication -->
          <div class="bg-white mt-3 black-color">
            <div class="bg-tabs-custom w-100">
              <button
                class="w-100 text-left btn-expand bg-tabs-custom"
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <b-row class="m-0 p-0 bg-tabs-custom">
                  <b-col>
                    <b-form-checkbox
                      v-model="communicationSelect"
                      @click.native.prevent
                      >Message</b-form-checkbox
                    >
                  </b-col>
                </b-row>
              </button>
            </div>
            <div
              :class="id === '0' ? ' pb-3 collapse ' : 'pb-3 collapse show '"
              id="collapseExample"
            >
              <b-row class="my-3 mx-2">
                <!-- SMS -->
                <b-col md="4">
                  <DisplayCommunication
                    :title="'Message'"
                    :edit="smsEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarSMS"
                    @deleteMessage="deleteMessage"
                    :idCommu="2"
                    :disableStatus="disableStatus"
                    @modalTestSend="modalTestSend"
                    :estimatedCustomer="estimatedCustomer"
                  />
                </b-col>
                <!-- Email -->
                <b-col md="4">
                  <DisplayCommunication
                    :title="'Email'"
                    :edit="emailEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarEmail"
                    @deleteMessage="deleteMessage"
                    :idCommu="1"
                    :disableStatus="disableStatus"
                    @modalTestSend="modalTestSend"
                    :estimatedCustomer="estimatedCustomer"
                  />
                </b-col>
                <!-- Line -->
                <b-col md="4">
                  <DisplayCommunicationLine
                    :title="'Line'"
                    :edit="lineEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarLine"
                    :idCommu="3"
                    @deleteMessage="deleteMessage"
                    :disableStatus="disableStatus"
                    @modalTestSend="modalTestSend"
                    :estimatedCustomer="estimatedCustomer"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-3 mx-2">
                <b-col>
                  <b-form-checkbox
                    v-model="form.sent_all"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Send Messages On All Channels
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- time -->
          <div class="mt-3 bg-white">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col class="text-left">
                  <button
                    @click.prevent="showTimeMessage = true"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="timeMessageSelect"
                      >Set Time</b-form-checkbox
                    >
                  </button>
                </b-col>
                <b-col
                  class="text-right"
                  v-if="!showTimeMessage && disableStatus === false"
                >
                  <button
                    class="btn-edit w-100 text-right"
                    @click="showTimeMessage = true"
                    v-if="!showTimeMessage && !disableStatus"
                  >
                    Edit
                  </button>
                </b-col>
                <b-col
                  v-if="showTimeMessage && disableStatus === false"
                  class="text-right"
                >
                  <span>
                    <button
                      class="btn-edit-general btn-line-right py-0"
                      @click.prevent="saveTimeSendMessage"
                    >
                      Save
                    </button>
                    <button
                      class="btn-edit-general py-0"
                      @click="showTimeMessage = false"
                    >
                      Cancel
                    </button>
                  </span>
                </b-col>
              </b-row>
            </div>
            <div class="bg-white p-3 mb-3 black-color" v-if="showTimeMessage">
              <SendMessageTimePanel
                :disableStatus="disableStatus"
                :v="$v"
                :form="form"
                :selected="form.is_send"
                @setTime="(val) => (form.is_send = val)"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Customer List" justified v-if="id !== '0'">
          <DisplayOverviewReport
            :userData="userData"
            :headerData="headerData"
            :filter="filter"
            :isBusy="isBusy"
            :id="id"
            :fields="fields"
            :showingTo="showingTo"
            :rows="rows"
            :type="'segmentation'"
            @searchUserRegister="searchUserRegister"
            @getDataInPagination="getDataInPagination"
          />
        </b-tab>
      </b-tabs>
      <SidebarConditionGroup
        ref="sidebarCondition"
        :selected="conditionSettingValue"
        :conditionList="conditionList"
        :customerTag="customerTag"
        :gender="genderList"
        :memberLevel="memberLevel"
        :stampList="stampCard"
        :branchList="branchList"
        :productList="productList"
        :campaignList="campaignList"
        :serviceList="serviceList"
        :privilegeList="privilegeCodeList"
        :formMain="form"
        :operatorList="operatorList"
        :listCondition="JSON.parse(JSON.stringify(form.condition_group))"
        @settingCondition="conditionValue"
        @setForm="setForm"
        :v="$v.form.condition_group"
      />

      <SidebarConsent
        ref="sidebarConsent"
        @setConsent="setConsent"
        :isConsent="form.is_not_consent_send"
      />
      <SidebarSMSPanel
        ref="sidebarSMS"
        :form="form.communication"
        @settingSMS="communicationValue"
        :formMain="form"
        typeSMS="segment"
      />
      <SidebarEmailPanel
        ref="sidebarEmail"
        :form="form.communication"
        @settingEmail="communicationValue"
        :formMain="form"
        typeEmail="segment"
      />
      <SidebarLinePanel
        ref="sidebarLine"
        :form="form.communication"
        :v="$v.form.communication"
        :communicationList="communicationList"
        :messageType="messageType"
        :id="id"
        :dataImage="dataImage"
        @settingLine="communicationValue"
        :formMain="form"
        typeLine="segment"
      />
      <MemberTierSidebar
        ref="sidebarMemberTier"
        :selected="attrSettingValue"
        @settingAction="attrValue"
        :dropdownAction="dropdownAction"
        :redeemCodeList="redeemCodeList"
        :form="form.list_attribute"
        :v="$v.form.list_attribute"
        :memberLevel="memberLevel"
        @submit="attrValue"
      />
      <SidebarActionPanel
        ref="sidebarAction"
        :selected="actionSettingValue"
        @settingAction="actionValue"
        :dropdownAction="dropdownAction"
        :dropdownAction1="dropdownAction1"
        :redeemCodeList="redeemCodeList"
        :form="form.action"
        :v="$v.form.action"
      />
      <TestSendSmsEmail
        ref="modalTestSend"
        @testSendEmailSMS="testSendEmailSMS"
      />
    </form>
    <div class="footer-action">
      <b-col>
        <router-link to="/automation/sendmessage">
          <b-btn class="btn-cancel btn-width">Cancel</b-btn>
        </router-link>
        <!-- <b-btn
          variant="danger"
          v-if="id !== '0'"
          class="btn-cancel btn-width ml-sm-2 mt-2 mt-sm-0"
          @click.prevent="deleteAutomationFunc"
        >
          Delete
        </b-btn> -->
      </b-col>
      <b-col class="text-right">
        <b-btn
          class="mr-sm-2"
          style="width: 125px"
          variant="info"
          @click.prevent="sendForm(1)"
          v-if="id == 0 || form.is_draft == 1"
        >
          Save Draft
        </b-btn>
        <b-btn class="main-color btn-save mt-2 mt-sm-0" @click="sendForm(0)">
          Save
        </b-btn>
      </b-col>
    </div>
    <!-- <FooterAction
      routePath="/automation/sendmessage"
      @submit="sendForm(0)"
      :hideSubmit="$route.params.id != 0"
    /> -->
  </div>
</template>

<script>
import GeneralPanel from "@/components/automation/segmentation/GeneralPanel";
import SendMessageTimePanel from "@/components/automation/segmentation/SendMessageTimePanel";
import SidebarConditionGroup from "@/components/automation/component/SidebarConditonGroup";
import SidebarSMSPanel from "@/components/automation/component/SidebarSMSPanel";
import SidebarConsent from "@/components/automation/component/SidebarConsent";
import SidebarEmailPanel from "@/components/automation/component/SidebarEmailPanel";
import SidebarLinePanel from "@/components/automation/component/SidebarLinePanel";
import DisplayCommunication from "@/components/automation/communication/DisplayCommunication";
import DisplayCommunicationLine from "@/components/automation/communication/DisplayCommunicationLine";
import DisplayResultAutomation from "@/components/automation/component/DisplayResultSendMessageGroup";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import DisplayOverviewReport from "@/components/automation/detail/DisplayOverviewReport";
import SidebarActionPanel from "@/components/automation/component/SidebarActionPanel";
import TestSendSmsEmail from "@/components/automation/component/TestSendSmsEmail";
import Goal from "./components/Goal";
import MemberTierSidebar from "./components/MemberTier/Sidebar";
export default {
  components: {
    GeneralPanel,
    SidebarConditionGroup,
    SidebarSMSPanel,
    SidebarConsent,
    SidebarEmailPanel,
    SidebarLinePanel,
    OtherLoading,
    DisplayCommunication,
    DisplayCommunicationLine,
    SendMessageTimePanel,
    DisplayResultAutomation,
    DisplayOverviewReport,
    SidebarActionPanel,
    TestSendSmsEmail,
    Goal,
    MemberTierSidebar,
  },
  data() {
    return {
      eventList: [],
      conditionList: [],
      memberLevel: [],
      genderList: [],
      communicationList: [],
      campaignList: [],
      isLoading: true,
      messageType: [],
      customerTag: [],
      form: {
        name: "",
        send_time: "",
        is_send: 0,
        is_not_consent_send: 0,
        is_not_variable: 1,
        is_draft: 0,
        sent_all: 0,
        segmentationType: null,
        campaign_id: null,
        condition_group: [
          {
            group: 1,
            import_condition: "",
            group_operation_type: "and",
            operation_type: "",
            condition: [],
          },
        ],
        action: [],
        communication: [],
        list_attribute: [],
        import_condition: "",
        goal: {
          id: 0,
          marketing_automation_id: 0,
          message_segmentation_id: Number(this.$route.params.id),
          event_type_id: 6,
          is_goal: 1,
          value: 30,
        },
      },
      id: this.$route.params.id,
      selectCommu: [],
      operatorList: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: ">=",
        },
        {
          id: 5,
          name: "<=",
        },
        {
          id: 6,
          name: "between",
        },
      ],
      dataImage: [],
      showInformation: false,
      errorCampaign: false,
      generalInformationSelect: false,
      conditionSelect: false,
      timeMessageSelect: false,
      communicationSelect: false,
      showCondition: false,
      conditionSettingValue: 0,
      showTimeMessage: false,
      showCommunication: false,
      smsEdit: false,
      emailEdit: false,
      lineEdit: false,
      confirmMsg: "",
      currentTime: "",
      segmentationSelect: 0,
      disableStatus: false,
      userData: {
        result_count: 0,
        email_count: 0,
        sms_count: 0,
        line_count: 0,
        not_succes_count: 0,
      },
      isBusy: true,
      filter: {
        search: "",
        page: 1,
        take: 10,
        channel: 0,
      },
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "firstname",
          label: "Firstname",
        },
        {
          key: "lastname",
          label: "Lastname",
        },
        {
          key: "channel_sender",
          label: "Channel Sender",
          class: "w-150",
        },
        {
          key: "sender_time",
          label: "Sender Time",
          class: "w-150",
        },
        {
          key: "message_receiver",
          label: "Message Receiver",
        },
        {
          key: "is_goal",
          label: "Convert",
        },
      ],
      checkSentAll: false,
      items: [],
      rows: 0,
      showingTo: 0,
      privilegeCodeList: [],
      allPrivilegeCode: [],
      redeemCodeList: [],
      dropdownAction: [
        { id: 1, name: "Redeem Code" },
        { id: 2, name: "Add Point" },
        { id: 6, name: "Clear Point" },
      ],
      dropdownAction1: [{ id: 1, name: "Redeem Code" }],
      actionSettingValue: 2,
      actionSelect: false,
      formTestSMS: {
        text: "",
        email: "",
        telephone: "",
        type_communication: 0,
        message_type_id: Number(this.$route.params.id) == 0 ? 3 : 2,
        tag_id: Number(this.$route.params.id),
      },
      stampCard: [],
      branchList: [],
      productList: [],
      serviceList: [],
      estimatedCustomer: {
        userAll: 0,
        userSend: 0,
        userPercen: 0,
        userTelephone: 0,
        userEmail: 0,
        userLine: 0,
      },
      isConsentSet: false,
      tabIndex: 0,
      isLoadReport: false,
      headerData: {},
      attrSelect: false,
      attrSettingValue: 2,
    };
  },
  watch: {
    tabIndex(val) {
      if (val == 1) {
        this.getReport();
        this.getUserRegister();
      }
    },
  },
  async created() {
    this.getCustomerTag();
    this.getOption();
    this.getEvent();
    this.getConditionList();
    this.getCommunication();
    this.getMessageType();
    this.getCampagin();
    this.getStampCard();
    if (this.id !== "0") {
      this.getDetail();
    }
  },
  validations: {
    form: {
      name: { required },
      send_time: {
        required: requiredIf(function (item) {
          return this.form.is_send === 2;
        }),
      },
      segmentationType: { required },
      campaign_id: {
        required: requiredIf(function (item) {
          return this.form.segmentationType === 2;
        }),
      },
      condition_group: {
        $each: {
          condition: {
            $each: {
              condition_type_id: {
                required: requiredIf(function (item) {
                  return !item.condition_type_id;
                }),
              },
              operation_type_id: {
                required: requiredIf(function (item) {
                  return !item.operation_type_id;
                }),
              },
              condition_value: {
                required: requiredIf(function (item) {
                  return !item.condition_value;
                }),
              },
            },
          },
        },
      },
      list_attribute: {
        $each: {
          automation_attribute_type_id: {
            required: requiredIf(function (item) {
              return this.attrSettingValue == 1;
            }),
          },
          value: {
            required: requiredIf(function (item) {
              return this.attrSettingValue == 1;
            }),
          },
        },
      },

      action: {
        $each: {
          action_type_id: {
            required: requiredIf(function (item) {
              return !item.action_type_id;
            }),
          },
          action_value: {
            required: requiredIf(function (item) {
              return !item.action_value;
            }),
          },
        },
      },
      communication: {
        $each: {
          detail_list: {
            $each: {
              sort_order: { required },
              communication_value: {
                $each: {
                  value: {
                    required: requiredIf(function (item) {
                      return [7, 10, 11, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                  image_url: {
                    required: requiredIf(function (item) {
                      return [7, 8, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                },
              },
              format_list: {
                $each: {
                  option_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                  },
                  intent_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                  },

                  header_name: {
                    required: requiredIf(function (item) {
                      return [14].includes(parseInt(item.message_type_id));
                    }),
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  methods: {
    async getOption() {
      return await Promise.all([
        this.getGender(),
        this.getMemberLevel(),
        this.getCampagin(),
        this.getPrivilegeCode(),
        this.getRedeemCode(),
        this.getStampCard(),
        this.getBranchList(),
        this.getProductList(),
        this.getCampaignList(),
        this.getServiceList(),
      ]);
    },
    setForm(val) {
      this.form.condition_group = val;
    },
    modalTestSend(val) {
      this.$refs.modalTestSend.show(val);
    },
    sideBarAction() {
      this.$refs.sidebarAction.show(true);
    },
    actionValue(val) {
      this.actionSelect = true;
      this.actionSettingValue = val;
    },
    deleteMessage(val) {
      if (val === 2) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 2
        );
        this.form.communication.splice(findIndexValue, 1);
        this.smsEdit = false;
      } else if (val === 1) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 1
        );
        this.form.communication.splice(findIndexValue, 1);
        this.emailEdit = false;
      } else if (val === 3) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 3
        );
        this.form.communication.splice(findIndexValue, 1);
        this.lineEdit = false;
      }
    },
    conditionValue(val, form) {
      if (val) {
        this.conditionSelect = true;
        this.conditionSettingValue = val;
      }
      this.getSendCustomer();
    },
    async getRedeemCode() {
      await this.$store.dispatch("getRedeemCode", this.id);
      let data = this.$store.state.automation.redeemCodeList;
      if (data.result == 1) {
        this.redeemCodeList = data.detail;
      }
    },
    communicationValue(val) {
      if (val === 2) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.smsEdit = true;
      } else if (val === 1) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.emailEdit = true;
      } else if (val === 3) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.lineEdit = true;
      }
    },
    sideBarCondition() {
      if (!this.disableStatus) this.$refs.sidebarCondition.show(true);
    },
    sidebarConsent() {
      if (!this.disableStatus) this.$refs.sidebarConsent.show(true);
    },
    sideBarSMS() {
      const findTypeSMS = this.form.communication.find(
        (el) => el.communication_type_id === 2
      );
      if (!findTypeSMS) {
        this.form.communication.push({
          communication_type_id: 2,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarSMS.show(true);
    },
    sideBarEmail() {
      const findTypeEmail = this.form.communication.find(
        (el) => el.communication_type_id === 1
      );
      if (!findTypeEmail) {
        this.form.communication.push({
          communication_type_id: 1,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarEmail.show(true);
    },
    sideBarLine() {
      const findTypeLine = this.form.communication.find(
        (el) => el.communication_type_id === 3
      );
      if (!findTypeLine) {
        this.form.communication.push({
          communication_type_id: 3,
          detail_list: [
            {
              communication_value: [
                {
                  message_type_id: "10",
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: "10",
              sort_order: 1,
            },
          ],
          line_preview_text: "",
        });
      }
      this.showCommunication = true;
      this.$refs.sidebarLine.show(true);
    },
    showCollapse(val) {
      if (val === 1) {
        this.showInformation = true;
      } else if (val === 2) {
        if (this.showCondition === false) {
          this.showCondition = true;
        } else {
          this.showCondition = false;
        }
      } else if (val === 3) {
        if (this.showTimeMessage === false) {
          this.showTimeMessage = true;
        }
      }
    },
    hideCollapse(val) {
      if (val === 1) {
        this.showInformation = false;
      } else if (val === 3) {
        if (this.showTimeMessage) {
          this.showTimeMessage = false;
        }
      }
    },
    manageGroupCondition(obj) {
      for (const group of obj) {
        var tempObj = [];

        for (const list of group.condition) {
          if (
            !tempObj.find(
              (el) => el.condition_type_id == list.condition_type_id
            )
          ) {
            let value = group.condition
              .filter((el) => el.condition_type_id == list.condition_type_id)
              .map((el) => el.condition_value);
            tempObj.push({
              ...list,
              condition_value: value.length == 1 ? value[0] : value,
            });
          }
        }
        group.condition = tempObj;
      }
      return obj;
    },
    async getDetail() {
      await this.$store.dispatch("getSegmentationById", this.id);
      const data = this.$store.state.automation.segmentationById;
      if (data.result === 1) {
        for (const group of data.detail.condition_group) {
          var tempObj = [];
          group.group_operation_type =
            group.group_operation_type || group.condition.length > 0
              ? group.condition[0].group_operation_type || "and"
              : "and";
          for (const list of group.condition) {
            if (
              !tempObj.find(
                (el) => el.condition_type_id == list.condition_type_id
              )
            ) {
              let value = group.condition
                .filter((el) => el.condition_type_id == list.condition_type_id)
                .map((el) => el.condition_value);
              tempObj.push({
                ...list,
                condition_value: value.length == 1 ? value[0] : value,
              });
            }
          }
          group.condition = tempObj;
        }
        this.form = data.detail;

        this.generalInformationSelect = true;
        this.showInformation = true;
        this.conditionSelect = true;
        this.isConsentSet = true;

        if (this.form.condition_group.length === 0) {
          this.conditionSettingValue = 2;
        } else {
          this.conditionSettingValue = 1;
        }
        if (this.form.action.length === 0) {
          this.actionSettingValue = 2;
        } else {
          this.actionSettingValue = 1;
        }
        if (this.form.campaign_id) {
          this.form.segmentationType = 2;
        } else {
          this.form.segmentationType = 1;
          this.form.campaign_id = null;
        }
        if (this.form.status_id !== 5) {
          this.disableStatus = true;
        }
        if (this.form.list_attribute.length === 0) {
          this.attrSettingValue = 2;
        } else {
          this.attrSettingValue = 1;
        }
        this.actionSelect = true;
        this.attrSelect = true;
        this.timeMessageSelect = true;
        this.communicationSelect = true;
        this.showTimeMessage = true;
        this.form.is_send = this.form.is_send === 0 ? 1 : this.form.is_send;
        if (this.form.communication) {
          const findEMail = this.form.communication.find(
            (el) => el.communication_type_id === 1
          );
          const findSMS = this.form.communication.find(
            (el) => el.communication_type_id === 2
          );
          const findLine = this.form.communication.find(
            (el) => el.communication_type_id === 3
          );
          if (findEMail) {
            this.emailEdit = true;
          }
          if (findSMS) {
            this.smsEdit = true;
          }
          if (findLine) {
            this.lineEdit = true;
          }
        }
        this.isLoading = false;
        // this.$refs.goal.isGoalSelect = true;
      }
      this.getSendCustomer();
      this.isLoading = false;
    },
    getDataInPagination(value) {
      this.filter = value;
      this.getUserRegister();
    },
    async getUserRegister() {
      this.isLoadReport = false;
      let reqData = {
        id: this.id,
        filter: this.filter,
      };
      await this.$store.dispatch("getUserSendMessage", reqData);
      const data = this.$store.state.automation.userSegmentation;
      if (data.result == 1) {
        this.userData = data.detail;
        this.items = data.detail.result;
        this.rows = data.detail.result_count;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isBusy = false;
      }
      this.isLoadReport = true;
    },
    async getReport() {
      const resp = await this.axios(
        `/message/getoverviewheaderbyid/${this.$route.params.id}`
      );

      this.headerData = resp.data.detail;
    },
    async searchUserRegister() {
      await this.getUserRegister();
      // await this.getReport();
    },
    async getPrivilegeCode() {
      // await this.$store.dispatch("getPrivilegeCodeInAutomation");
      const resp = await this.axios("/MarketingAutomation/getprivilege/0");
      const resp2 = await this.axios("/MarketingAutomation/getprivilege/1");
      this.allPrivilegeCode = resp.data.detail;
      // const data = this.$store.state.automation.privilegeCodeInAutomation;
      // if (data.result == 1) {
      this.privilegeCodeList = resp2.data.detail;
    },
    async getEvent() {
      await this.$store.dispatch("getEvent");
      let data = this.$store.state.automation.eventList;
      if (data.result == 1) {
        this.eventList = data.detail;
      }
    },
    async getConditionList() {
      await this.$store.dispatch("getCondition");
      let data = this.$store.state.automation.conditionList;

      if (data.result == 1) {
        this.conditionList = data.detail.filter(
          (el) =>
            el.id != 12 &&
            el.id != 13 &&
            el.id != 14 &&
            el.id != 20 &&
            el.id != 30 &&
            el.id != 31
        );
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevel = data.detail;
      }
    },
    async getStampCard() {
      let data = await this.axios(`/MarketingAutomation/getstampcard`);

      this.stampCard = data.data.detail;
    },
    async getBranchList() {
      let data = await this.axios(`/MarketingAutomation/GetBranchList`);

      this.branchList = data.data.detail;
    },
    async getProductList() {
      let data = await this.$store.dispatch(`getProductList`, {
        take: 999999,
        page: 1,
        search: "",
      });
      this.productList = data.data.detail.product_list;
    },
    async getCampaignList() {
      let data = await this.axios(`/MarketingAutomation/GetCampaignList`);
      this.campaignList = data.data.detail;
    },
    async getServiceList() {
      let data = await this.axios(`/MarketingAutomation/GetServiceList`);
      this.serviceList = data.data.detail;
    },
    async getGender() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async getCommunication() {
      await this.$store.dispatch("getCommunication");
      let data = this.$store.state.automation.communicationList;
      if (data.result == 1) {
        this.communicationList = data.detail;
      }
    },
    async getMessageType() {
      await this.$store.dispatch("getMessageType");
      let data = this.$store.state.automation.messageType;
      if (data.result == 1) {
        this.messageType = data.detail;
        if (this.id === "0") {
          this.isLoading = false;
        }
      }
    },
    async getCampagin() {
      await this.$store.dispatch("getCampaign");
      let data = this.$store.state.automation.campaignList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    setCurrentTime() {
      const currentdate = new Date();
      var hour = "";
      var minutes = "";
      var second = "";
      if (JSON.stringify(currentdate.getHours()).length < 2) {
        hour = "0" + currentdate.getHours();
      } else {
        hour = currentdate.getHours();
      }
      if (JSON.stringify(currentdate.getMinutes()).length < 2) {
        minutes = "0" + currentdate.getMinutes();
      } else {
        minutes = currentdate.getMinutes();
      }
      if (JSON.stringify(currentdate.getSeconds()).length < 2) {
        second = "0" + currentdate.getSeconds();
      } else {
        second = currentdate.getSeconds();
      }
      this.currentTime =
        currentdate.getFullYear() +
        "-" +
        (currentdate.getMonth() + 1) +
        "-" +
        currentdate.getDate() +
        "T" +
        hour +
        ":" +
        minutes +
        ":" +
        second +
        ".000+07:00";
    },
    submitConditionInForm() {
      // delete field in condition
      if (this.conditionSettingValue === 1) {
        for (const group of this.form.condition_group) {
          for (const condition of group.condition) {
            if (condition.condition_type_id === 1) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
            if (condition.condition_type_id === 2) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
            if (
              condition.condition_type_id === 3 ||
              condition.condition_type_id === 4 ||
              condition.condition_type_id === 5
            ) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
          }
        }
      } else {
        this.form.condition = [];
      }
    },
    submitActionInForm() {
      // delete field in action
      if (this.actionSettingValue === 1) {
        for (const action of this.form.action) {
          if (action.action_type_id === 1) {
            delete action.action_type_name;
          }
          if (action.action_type_id === 2) {
            delete action.pointId;
            delete action.pointName;
            delete action.action_type_name;
          }
        }
      } else {
        this.form.action = [];
      }
    },
    submitCommunicationInForm() {
      // check communication
      const findCommuSMS = this.form.communication.filter(
        (el) => el.communication_type_id === 2
      );
      const findCommuEmail = this.form.communication.filter(
        (el) => el.communication_type_id === 1
      );
      const findCommuLine = this.form.communication.filter(
        (el) => el.communication_type_id === 3
      );
      if (findCommuSMS.length > 0) {
        const chkValueSMS =
          findCommuSMS[0].detail_list[0].communication_value[0].value;
        if (!chkValueSMS) {
          const findIndexSMS = this.form.communication.findIndex(
            (sms) => sms.communication_type_id === 2
          );
          this.form.communication.splice(findIndexSMS, 1);
        }
      }
      if (findCommuEmail.length > 0) {
        const chkValueEmail =
          findCommuEmail[0].detail_list[0].communication_value[0].value;
        if (!chkValueEmail) {
          const findIndexEmail = this.form.communication.findIndex(
            (email) => email.communication_type_id === 1
          );
          this.form.communication.splice(findIndexEmail, 1);
        }
      }
    },
    async sendForm(draft) {
      await this.setCurrentTime();
      await this.submitConditionInForm();
      await this.submitActionInForm();
      await this.submitCommunicationInForm();
      this.form.is_draft = draft;
      this.form.is_send = this.form.is_send === 0 ? 1 : this.form.is_send;
      this.form.send_time =
        this.form.is_send === 2 ? this.form.send_time : this.currentTime;
      const findAction = this.form.action.some(
        (el) => el.action_value === null
      );
      if (findAction) this.form.action = [];

      if (draft === 1) {
        this.isDraftAutomation();
      } else {
        if (!this.generalInformationSelect) {
          this.warningAlert("Please save general information.");
          return;
        } else {
          this.$v.form.name.$touch();
          this.$v.form.segmentationType.$touch();
          this.$v.form.campaign_id.$touch();
          if (
            this.$v.form.name.$error ||
            this.$v.form.campaign_id.$error ||
            this.$v.form.segmentationType.$error
          ) {
            this.warningAlert("Please save general information.");
            this.generalInformationSelect = false;
            return;
          }
        }
        if (!this.conditionSelect) {
          this.warningAlert("Please set the terms of access rights.");
          return;
        }
        if (!this.actionSettingValue) {
          this.warningAlert("Please specify the use of special privileges.");
          return;
        }
        if (!this.attrValue) {
          this.warningAlert("Please save Set Attribute");

          return;
        }
        if (!this.isConsentSet) {
          this.warningAlert("Please save consent status");
          return;
        }
        if (!this.communicationSelect) {
          this.warningAlert("Please enter a message.");
          return;
        } else {
          const findSMS = this.form.communication.find(
            (el) => el.communication_type_id === 2
          );
          const findEmail = this.form.communication.find(
            (e) => e.communication_type_id === 1
          );
          const findLine = this.form.communication.find(
            (l) => l.communication_type_id === 3
          );

          if (!findSMS && !findEmail && !findLine) {
            this.warningAlert(
              "Please add at least one channel to access information."
            );
            return;
          }
        }
        if (!this.$refs.goal.isGoalSelect)
          return this.warningAlert("Please save goal");

        if (!this.timeMessageSelect) {
          this.warningAlert("Please note the time of sending the message.");
          return;
        } else {
          if (this.form.is_send === 0) {
            this.warningAlert("Please set the time for sending the message.");
            return;
          }
        }
        this.isDraftAutomation();
      }
    },
    async isDraftAutomation() {
      this.isLoading = true;
      this.form.campaign_id =
        this.form.segmentationType === 2 ? this.form.campaign_id : 0;
      delete this.form.segmentationType;

      for (const group of this.form.condition_group) {
        var tempObj = [];

        for (const list of group.condition) {
          delete list.id;

          list.operation_value = list.operation_value
            ? list.operation_value
            : 0;
          list.date_type = list.date_type ? list.date_type : 0;
          if (typeof list.condition_value == "object") {
            for (const value of list.condition_value) {
              tempObj.push({ ...list, condition_value: value });
            }
          } else {
            tempObj.push(list);
          }
        }

        group.condition = tempObj;
      }

      if (this.id === "0") {
        await this.$store.dispatch("createSegmentation", this.form);
        const data = this.$store.state.automation.respCreateSegmentation;
        if (data.result === 1) {
          this.successAlert().then(() => {
            this.$router.push("/automation/sendmessage");
          });
        } else {
          this.isLoading = false;
          this.errorAlert(data.message);
        }
      } else {
        let reqData = {
          id: this.id,
          form: this.form,
        };
        delete this.form.id;
        await this.$store.dispatch("updateSegmentation", reqData);
        const dataUpdate = this.$store.state.automation.respUpdateSegmentation;
        if (dataUpdate.result === 1) {
          this.successAlert().then(() => {
            this.$router.push("/automation/sendmessage");
          });
        } else {
          this.isLoading = false;
          this.warningAlert(dataUpdate.message);
        }
      }
    },
    closeAutomation() {
      this.$router.push("/automation/sendmessage");
    },
    saveInformation() {
      this.$v.form.name.$touch();
      this.$v.form.segmentationType.$touch();
      this.$v.form.campaign_id.$touch();
      if (
        this.$v.form.name.$error ||
        this.$v.form.campaign_id.$error ||
        this.$v.form.segmentationType.$error
      ) {
        this.generalInformationSelect = false;
        return;
      } else {
        this.generalInformationSelect = true;
      }
    },
    saveTimeSendMessage() {
      if (this.form.is_send === 0) {
        this.warningAlert("Please set time");
        return;
      } else if (this.form.is_send === 2) {
        this.$v.form.send_time.$touch();
        if (this.$v.form.send_time.$error) {
          return;
        }
      }
      this.timeMessageSelect = true;
    },
    showFooter() {
      if (this.id === "0") {
        return true;
      } else {
        if (this.form.status_id === 5) {
          return true;
        } else {
          return false;
        }
      }
    },
    async testSendEmailSMS(val) {
      if (val.type === 2 || val.type === 4) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = val.type;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === val.type
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 1) {
        this.formTestSMS.email = val.data;
        this.formTestSMS.telephone = "";
        this.formTestSMS.type_communication = 1;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === 1
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 3) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = val.type;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === val.type
        );

        this.formTestSMS.line_detail = SMS.detail_list;
        this.formTestSMS.line_preview_text = SMS.line_preview_text;
      }
      await this.actionTestSendEmailSMS();
    },
    async actionTestSendEmailSMS() {
      await this.$store.dispatch("testSendSMSOrEmail", this.formTestSMS);
      const data = this.$store.state.automation.stateTestSendSMSOrEmail;

      this.successAlert();
    },
    downloadTemplate() {
      window.open(
        `${process.env.VUE_APP_API_TEMPLATE}/template/template_for_send_message_customer_upload.xlsx`,
        "_blank"
      );
    },
    async getSendCustomer() {
      let obj = JSON.parse(JSON.stringify(this.form.condition_group));
      if (this.conditionSettingValue == 2) {
        obj = [
          {
            group: 1,
            import_condition: "",
            operation_type: "",
            condition: [],
          },
        ];
      } else {
        for (const group of obj) {
          var tempObj = [];

          for (const list of group.condition) {
            list.operation_value = list.operation_value
              ? list.operation_value
              : 0;
            list.date_type = list.date_type ? list.date_type : 0;
            if (typeof list.condition_value == "object") {
              for (const value of list.condition_value) {
                tempObj.push({ ...list, condition_value: value });
              }
            } else {
              tempObj.push(list);
            }
          }

          group.condition = tempObj;
        }
        if (obj.length == 0) {
          obj = [
            {
              group: 1,
              import_condition: "",
              operation_type: "",
              condition: [],
            },
          ];
        }
      }
      const resp = await this.axios.post(
        `/Message/CheckUserCondition/${this.form.is_not_consent_send}`,
        obj
      );

      this.estimatedCustomer = resp.data.detail;
    },
    setConsent(val) {
      this.form.is_not_consent_send = val;
      this.isConsentSet = true;
      this.getSendCustomer();
    },
    submitGoal(val) {
      this.$refs.goal.isGoalSelect = true;
      this.form.goal = val;
    },
    async getCustomerTag() {
      const data = await this.axios("/MarketingAutomation/getcustomertag");

      this.customerTag = data.data.detail;
    },
    attrValue(val) {
      if (val == 2) this.form.list_attribute = [];
      this.attrSelect = true;
      this.attrSettingValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--theme-secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.bg-tabs-custom:active {
  background-color: var(--theme-secondary-color) !important;
}
.ft-20 {
  font-size: 20px;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
::v-deep .header-tablepage {
  color: #092d53;
}
.btn-edit-general {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--primary-color);
  border-radius: 0px;
}
.btn-line-right {
  border-right: 1px solid var(--primary-color);
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
.add-new {
  text-decoration: underline;
}
.font-w-600 {
  font-weight: 600;
}
.black-color {
  color: #000;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-edit-communication {
  color: gray;
  font-weight: 100;
}
.bg-white {
  background-color: #fff;
}
.pd-bt-100 {
  padding-bottom: 100px;
}
.btn-expand {
  padding: 0;
  background-color: transparent;
  border: none;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
.btn-download {
  background-color: #fff;
  color: var(--primary-color);
}
</style>
